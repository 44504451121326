import {SENTRY_DSN} from '@configurations/platform';
import {AccessTokenUtils} from '@core/authentication';
import {Config} from '@core/configuration';
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from '@core/routes';
import {Defaults as ReactVaporDefaults} from '@coveord/plasma-react';
import {init, reactRouterV6BrowserTracingIntegration} from '@sentry/react';
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import {useEffect} from 'react';
import {createRoot} from 'react-dom/client';

import {MainApplication} from '../applications';

declare const ADMIN_UI_VERSION: string;

// Reset window.name value to prevent bugs when navigating to search results...
window.name = '';

// URI can only parse real query params. Ours are in the hash, so we need to replace the # by a ? to make it work.
const queryParams = new URLSearchParams(window.location.hash.replace(/^#/, '?'));

// Detect login and retrieve access token.
if (queryParams.get('access_token')) {
    const expiresIn = parseInt(queryParams.get('expires_in'), 10);

    // Access token could be there twice in the URL, make sure we use only the first.
    let accessToken = queryParams.get('access_token');
    accessToken = Array.isArray(accessToken) ? accessToken[0] : accessToken;

    AccessTokenUtils.setToken(accessToken, expiresIn);

    const originalRouteParts = window.location.href.split('#');
    let queryString = '';
    if (originalRouteParts.length > 1) {
        const lastPart = originalRouteParts[originalRouteParts.length - 1];
        if (lastPart) {
            queryParams.delete('access_token');
            queryParams.delete('expires_in');
            queryParams.delete('loggerId');
            queryParams.delete('scope');
            queryParams.delete('token_type');

            queryString = queryParams.toString();
            originalRouteParts.pop();
        }

        window.location.replace(`${originalRouteParts.join('#')}#${decodeURIComponent(queryString)}`);
    }
}

if (document.domain.includes('coveo.com')) {
    // Set domain to coveo.com to allow iframe cross subdomain
    document.domain = 'coveo.com';
}

Backbone.Syphon.InputReaders.register('default', (a) => {
    const t = a.val();
    const n = parseFloat(t);

    return isNaN(n) || typeof n !== 'number' || n.toString() !== t ? t : n;
});

/**
 * ReactRouter forces us to start our URLs with a `/`. This method simply add the starting slash if it's not there
 */
const addStartingSlashToHash = () => {
    const hash = window.location.hash;
    if (/^#[^\/]/.test(hash)) {
        window.location.hash = hash.replace(/^#(.+)/, '#/$1');
    }
};

addStartingSlashToHash();

if (
    !['localhost', 'local.cloud.coveo.com'].includes(window.location.hostname) // avoid sending errors from local server
) {
    try {
        init({
            dsn: SENTRY_DSN,
            environment: Config.envShort,
            release: `admin-ui_v${ADMIN_UI_VERSION}`,
            integrations: [
                reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
            ],
            beforeSend: (event) => {
                if (
                    /^Unable to preload CSS for (\/admin\/v[^\/]+)(\/assets\/[^-]+-)([^\.]+).css$/.test(
                        event.exception.values[0].value,
                    )
                ) {
                    event.fingerprint = ['preload-css-error'];
                }
                return event;
            },
            tracesSampleRate: 0.2,
            maxBreadcrumbs: 50,
            autoSessionTracking: true,
            attachStacktrace: true,
            ignoreErrors: [
                'Non-Error exception captured',
                'Non-Error promise rejection captured',
                'TypeError: Failed to fetch',
                'TypeError: NetworkError when attempting to fetch resource.',
                'AbortError',
                'HTTP/1.1 Overhead',
                'UnhandledRejection: Object captured as promise rejection with keys: errorCode, message, requestID',
            ],
            denyUrls: [/extension:\/\//i],
        });
    } catch (e) {
        // This is a fail-safe to make sure that if Sentry throws an exception, the admin still starts.
        console.error(`Sentry could not be initialized. Exception won't be logged in Sentry`);
    }
}

const root = createRoot(document.querySelector('.application-wrapper'));
root.render(<MainApplication />);

ReactVaporDefaults.MODAL_ROOT = '#admin';
ReactVaporDefaults.DROP_ROOT = '#admin-drop-container';

(window as any).MonacoEnvironment = {
    getWorker: (workerId, label) => {
        if (label === 'json') {
            return new jsonWorker();
        }
        return new editorWorker();
    },
};
